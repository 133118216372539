<template>
	<div>
		<Header :title="isEdit ? '修改线上会员卡等级' : '添加线上会员卡等级'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
			:wrapperCol="{span: 16, xxl: 15 }">

<!--			<a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必填项不允许为空'}]">-->
<!--				<a-select :disabled="isSee || isEdit" v-model:value="modelRef.organizationId" placeholder="请选择影院组织" show-search :filter-option="filterOption" @change="getCinemaList" v-if="isSee || isEdit">-->
<!--					<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>-->
<!--				</a-select>-->
<!--        <a-select :disabled="isSee || isEdit" v-model:value="modelRef.organizationId" placeholder="请选择影院组织" show-search :filter-option="filterOption" @change="getCinemaList" v-else>-->
<!--          <a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>-->
<!--        </a-select>-->
<!--			</a-form-item>-->
      <a-form-item label="是否一卡通" name="isOneCard" :rules="[{required: true, message: '必填项不允许为空'}]">
        <a-radio-group v-model:value="modelRef.isOneCard" :disabled="isSee">
<!--          <a-radio :value="1" :disabled="isOneCardOpen">-->
          <a-radio :value="1" :disabled="!isOneCardOpen">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="可用影院" name="cinemaIds" v-if="modelRef.isOneCard == 0">
        <a-form-item-rest>
<!--          <a-button @click="onSelectCinema" type="primary">选择影院</a-button>-->
          <selectCinema :disabled="isSee" v-model:value="modelRef.cinemaIds" :isEdit="isEdit" :isSee="isSee" :selectItem="cinemaTableList" @change="(dataList) => { cinemaTableList = dataList; }"></selectCinema>
        </a-form-item-rest>
        <div v-if="cinemaTableList.length" style="margin: 10px 0;">
          <a-table rowKey="id" :columns="cinemaColumns" :dataSource="cinemaTableList" size="small" :pagination="false">
            <template #bodyCell="{ column, record, index }">
              <template v-if="column.key === 'action'">
                <a-button :disabled="isSee" type="link" @click="onDeleteCinema(record, index)">删除</a-button>
              </template>
            </template>
          </a-table>
        </div>
<!--      </a-form-item>-->
      </a-form-item>

			<a-form-item label="等级名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input :disabled="isSee" v-model:value="modelRef.title" placeholder="请输入等级名称"></a-input>
			</a-form-item>

			<a-row>
				<a-col :offset="1" :span="20">
					<a-row>
<!--						<a-col :span="8">-->
<!--							<a-form-item label="卖品折扣" name="goodsDiscount" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]" extra='100%则卖品无优惠折扣'>-->
<!--								<a-input-number :disabled="isSee" v-model:value="modelRef.goodsDiscount" :min="0" :max="100" :precision="2" placeholder="请输入"></a-input-number> %-->
<!--							</a-form-item>-->
<!--						</a-col>-->
						<!-- <a-col :span="8">
							<a-form-item label="结算金额" name="deductionAmount" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.deductionAmount" :min="0" :precision="2" placeholder="请输入"></a-input-number> 元
							</a-form-item>
						</a-col> -->
						<!-- <a-col :span="8">
							<a-form-item label="限购票数" name="unsettledOrderLimit" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.unsettledOrderLimit" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<a-tooltip>
									<template #title>场次限购票数（单场次限购数, 0 不限购）</template>
									<Icon icon="QuestionCircleOutlined" style="margin-left: 8px;" ></Icon>
								</a-tooltip>
							</a-form-item>
						</a-col> -->
						<a-col :span="8">
							<a-form-item label="是否启用" name="isDisabled" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row>

			<!-- <a-row>
				<a-col :offset="1" :span="20">
					<a-row>

						<a-col :span="8">
							<a-form-item label="排序号" name="sort" :labelCol="{span: 11, xxl: 7}" :wrapperCol="{span: 12, xxl: 10 }" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number :disabled="isSee" v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
							</a-form-item>
						</a-col>
					</a-row>
				</a-col>
			</a-row> -->

			<a-form-item label="等级描述" name="remarks" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-textarea :disabled="isSee" v-model:value="modelRef.remarks" placeholder="请输入等级描述"></a-textarea>
			</a-form-item>

<!--			<a-row class="ui-header">使用政策</a-row>-->
<!--			<a-button v-if="!modelRef.cinemaId" disabled>请选择所属影院</a-button>-->
<!--			<policy v-model:value="policyModel" :disabled="isSee" :id="modelRef.cinemaId" v-else-if="!isSee" type="stored" @ok="onPolicyRuleOk"></policy>-->

<!--			<a-table :columns="columns" :dataSource="modelRef.cardPolicy" :pagination="false"  style="margin-top: 10px;">-->
<!--				<template #bodyCell="{column, record, index}">-->
<!--					<template v-if="column.key === 'type'">-->
<!--						{{ record.versionType === 3 ? '批量定价' : '场次定价' }}-->
<!--					</template>-->
<!--					<template v-else-if="column.key === 'isDisabled'">-->
<!--						<div v-if="record.isDisabled === 0 || record.isDisabled === true">-->
<!--							启用-->
<!--						</div>-->
<!--						<div v-else-if="record.isDisabled === 1 || record.isDisabled === false">-->
<!--							禁用-->
<!--						</div>-->
<!--					</template>-->
<!--					<template v-if="column.key === 'action'">-->
<!--						<div style="display: flex;">-->
<!--							<span>-->
<!--								<policy :disabled="isSee" :id="modelRef.cinemaId" type="stored" v-model:value="modelRef.cardPolicy[index]">-->
<!--									<slot><a-button type="primary" ghost>修改</a-button></slot>-->
<!--								</policy>-->
<!--							</span>-->
<!--							<a-button :disabled="isSee" style="margin-left: 20px;" type="danger" ghost @click="onDelete(index)">删除</a-button>-->
<!--						</div>-->
<!--					</template>-->
<!--				</template>-->
<!--			</a-table>-->

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" @click="onSubmit(true)">{{ isEdit ? '提交' : '提交' }}</a-button>
					<!-- <a-button v-if="!isEdit" style="margin-left: 20px;" type="primary" @click="onSubmit(false)">添加并继续</a-button> -->
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>

  </div>
</template>

<script>
	// import dayjs from 'dayjs';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import policy from '@/components/policy/index.vue';
  import { getOrganizationList, getCinemaAllList, getCinemaList } from "@/service/modules/cinema.js";
	import { saveCardPolicy, updateCardPolicy, getCardPolicyDetail } from '@/service/modules/coupon.js';
  import selectCinema from '@/components/selectCinema/index.vue'
  import movie from "@/components/movie/index.vue";
  import { getCinemaSetting } from '@/service/modules/system.js';
	export default {
		components: {
      movie,
			Icon,
			Header,
			policy,
      selectCinema
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			isSee: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			},
		},
		created() {
      this.getCinemaSetting();
			this.getOrganizationList();
			if (this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
        loading: false,
        organizationList: [],
        cinemaAllList: [],
        modelRef: {
          type: 3,
          cardType: 3,
          isDisabled: false,
          cardPolicy: [],
          isOneCard: 0,//是否一卡通, 0:否, 1:是
          cinemaIds:[],//可用影院列表
        },
        // columns: [{
        //   title: '政策名称',
        //   dataIndex: 'pricingName'
        // }, {
        //   title: '优先级',
        //   dataIndex: 'pricingLevel'
        // }, {
        //   title: '规则类型',
        //   key: 'type'
        // }, {
        //   title: '是否启用',
        //   key: 'isDisabled'
        // }, {
        //   title: '单次限购数',
        //   dataIndex: 'singleCount'
        // }, {
        //   title: '结算金额(元)',
        //   dataIndex: 'deductionAmount'
        // }, {
        //   title: '操作',
        //   key: 'action'
        // }],
        list: [],
        policyModel: {
          versionType: 3,
          isDisabled: true,
          filmType: 1,
          filmList: [],
          hallType: 1,
          hallList: [],
          isHoliday: false,
          visualType: 1,
          dimensionalList: [],
          showList: [],
          startDate: null,
          endDate: null,
          timeList: [],
          deductionAmount: ''
        },
        cinemaTableList:[],//可用影院表格列表
        cinemaColumns:[//可用影院表格表头
          {
            title: '影院编码',
            dataIndex: 'code'
          },
          {
            title: '影院名称',
            dataIndex: 'name'
          },
          {
            title: '操作',
            key: 'action'
          }
        ],
        showCinema:false,
        cinemaSelect: {
          selectedRowKeys: [],
          selectRecord: [],
          type: 'checkbox',
          hideSelectAll: true,
          onSelect: (record, selected)=>{
            this.onCinemaSelect(record, selected);
          }
        },
        isOneCardOpen: 1, //是否开放一卡/券通, 0: 不开放, 1: 开放
      }
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardPolicyDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
            ret.data.cinemaIds = ret.data.cinemaIds ? ret.data.cinemaIds.split(','): [];
            this.getCinemaList(ret.data.organizationId)
            // if(this.isSee || this.isEdit){
            //   //当是查看或者修改时候 获取全部影院
            //   // this.getCinemaAllList(ret.data.organizationId);
            //
            // }else {
            //   //当是添加时候 获取有权限的影院
            //   this.getCinemaList(ret.data.organizationId)
            // }
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit(isBack) {
				this.$refs.form.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
          let cinemaNameList = []
          let cinemaIdsList = []
          this.cinemaTableList.forEach(item=>{
            if (item.name){
              cinemaNameList.push(item.name)
            }
            if (item.id){
              cinemaIdsList.push(item.id)
            }
          })
          postData.cinemaId = 0
          if(postData.isOneCard == 0 && cinemaNameList.length == 0){
            return this.$message.warn('请添加可用影院');
          }
          postData.cinemaIds = cinemaIdsList?cinemaIdsList.toString():''
          postData.cinemaNames = cinemaNameList?cinemaNameList.toString():''
					postData.isDisabled = postData.isDisabled ? 0 : 1;
          if (postData.cinemaList){
            delete postData.cinemaList;
          }


					// if (postData.cardPolicy.length === 0) {
					// 	return this.$message.warn('请添加线上会员卡使用政策');
					// }
					// let hasSameLevel = false;
					// let levels = [];
					// postData.cardPolicy.forEach(item => {
					// 	// if (levels.indexOf(item.pricingLevel) !== -1) {
					// 	// 	hasSameLevel = true;
					// 	// }
					// 	// levels.push(item.pricingLevel);
          //
					// 	if (item.movieList) {
					// 		//  删掉不需要的字段
					// 		delete item.movieList;
					// 	}
					// 	if (item.cinemaHallList) {
					// 		//  删掉不需要的字段
					// 		delete item.cinemaHallList;
					// 	}
					// 	if (item.hallDataList) {
					// 		delete item.hallDataList;
					// 	}
					// 	if (item.versionType === 4 && item.filmFieldIds) {
					// 		item.filmFieldIds = item.filmFieldIds.join(',');
					// 	}
					// 	item.isDisabled = item.isDisabled ? 0 : 1;
					// 	if (item.versionType === 3) {
					// 		item.isHoliday = item.isHoliday ? 1 : 0;
					// 		item.filmList = item.filmList.join(',');
					// 		item.hallList = item.hallList.join(',');
					// 		item.dimensionalList = item.dimensionalList.join(',');
					// 		if (item.startDate) {
					// 			item.startDate = parseInt(this.moment(item.startDate).startOf('day').valueOf() / 1000);
					// 		} else {
					// 			item.startDate = 0;
					// 		}
					// 		if (item.endDate) {
					// 			item.endDate = parseInt(this.moment(item.endDate).endOf('day').valueOf() / 1000);
					// 		} else {
					// 			item.endDate = 0;
					// 		}
					// 		if(item.timeList) {
					// 			item.timeList.forEach(subItem => {
					// 				subItem.week = subItem.week.join(',');
					// 				subItem.startTime = this.moment(subItem.startTime).get('hour') * 60 *60 + this.moment(subItem.startTime).get('minute') * 60;
					// 				subItem.endTime = this.moment(subItem.endTime).get('hour') * 60 *60 + this.moment(subItem.endTime).get('minute') * 60;
					// 			})
					// 		}
					// 		delete item.showList;
					// 	} else {
					// 		delete item.filmFieldIds;
					// 		delete item.isHoliday;
					// 		delete item.movieList;
					// 		delete item.hallDataList;
					// 		delete item.filmType;
					// 		delete item.filmList;
					// 		delete item.hallType;
					// 		delete item.hallList;
					// 		delete item.visualType;
					// 		delete item.dimensionalList;
					// 		delete item.startDate;
					// 		delete item.endDate;
					// 		delete item.timeList;
					// 	}
					// });
					// if (hasSameLevel) {
					// 	return this.$message.warn('使用政策中含有相同优先级的政策，无法提交');
					// }
					try {
						let ret;
						this.loading = true;
						if (this.isEdit && this.id) {
							postData.id = this.id;
							ret = await updateCardPolicy(postData);
						} else {
							ret = await saveCardPolicy(postData)
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							if (isBack) {
								this.onBack(true);
							} else {
								this.cinemaAllList = [];
								this.$refs.form.resetFields();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				if (ret.code === 200) {
					this.organizationList = ret.data.list;
				}
			},
      filterArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => !values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
      hasArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
      async getCinemaList(organizationId) {
        this.cinemaAllList = [];
        this.modelRef.cinemaId = undefined;
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          organizationId: organizationId ? organizationId : undefined
        })
        if (ret.code === 200) {
          this.cinemaAllList = ret.data.list;
          //排除已选择
          if(this.isSee || this.isEdit){
            //已经使用的 hasArray
            this.cinemaTableList = this.hasArray(this.modelRef.cinemaIds,ret.data.list)
            //可以选择使用
            this.cinemaAllList = this.filterArray(this.modelRef.cinemaIds,ret.data.list)

          }
        }
      },
			onOrganizationChange(id) {
        if(this.isSee || this.isEdit){
          this.getCinemaAllList(id);
        }else {
          this.getCinemaList(id);
        }

			},
			filterOption(input, option) {
				return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},
			onPolicyRuleOk(data) {
        console.log("data",data)
				this.modelRef.cardPolicy.push(this.$deepClone(data));
				this.policyModel = {
					versionType: 3,
					isDisabled: true,
					filmType: 1,
					filmList: [],
					hallType: 1,
					hallList: [],
					isHoliday: false,
					visualType: 1,
					dimensionalList: [],
					showList: [],
					startDate: null,
					endDate: null,
					timeList: [],
					deductionAmount: ''
				};
			},
			onDelete(index) {
				this.$confirm({
					title: '提示',
					content: '确定移除吗？',
					onOk: () => {
						this.modelRef.cardPolicy.splice(index, 1);
					}
				})
			},
      onDeleteCinema(item, index) {
        let i = this.modelRef.cinemaIds.indexOf(item.id.toString());
        this.modelRef.cinemaIds.splice(i, 1);
        this.cinemaTableList.splice(index, 1);
      },
      async getCinemaSetting() {
        const ret = await getCinemaSetting({});
        if (ret.code === 200) {
          this.isOneCardOpen = ret.data.isBatchOpen;
          if (!this.isOneCardOpen) this.modelRef.isOneCard = 0;
        }
      }
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}
	.ui-flex {
		display: flex;
	}

	.ui-input {
		width: 180px;
		display: inline-block;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-margin__top40 {
		margin-top: 40px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #ccc;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}
</style>
